<template>
  <div class="copyright">
    <p class="mg-b-16 T5-2" v-if="showBrowserTip">
      <i class="iconfont icon-icon_tishi text-F3 T3"></i>
      为保证平台的正常使用，建议您尽量使用【谷歌浏览器】或【360浏览器-极速模式】进行浏览
    </p>
    <p class="T6-3">
      Copyright © 2017 - {{ new Date().getFullYear() }} <a href="https://www.qljy.com">广州青鹿教育科技有限公司</a> All
      Rights Reserved<span class="version">当前服务版本：{{ server.versionName }}</span>
    </p>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'copyrightHtml',
  props: {
    showBrowserTip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      server: (state) => state.login.server,
    }),
  },
  created() {
    this.$store.dispatch('login/fetchServerVersion', this.$platform);
  },
  mounted() {
    let copyright = document.querySelector('.copyright');
    window.copyrightHTML && copyright && (copyright.innerHTML = window.copyrightHTML);
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      @apply text-F2;
    }
  }

  .version::before {
    content: '';
    @apply relative top-4px w-1px h-12px mx-8px inline-block align-top bg-A7;
  }
}
</style>
