<template>
  <el-select
    v-model="schoolId"
    class="school-select"
    popper-class="school-select-popper"
    filterable
    placeholder="请选择学校"
    :title="schoolName"
    :no-data-text="emptyText"
    :filter-method="filterMethod"
    @change="selectSchool"
  >
    <i slot="prefix" class="iconfont icon-xuexiao"></i>
    <el-option
      v-for="item in schoolList"
      :key="item.schoolId"
      :value="item.schoolId"
      :label="item.wholeName"
      :title="item.wholeName"
      :class="['overflow-ellipsis', { active: item.schoolId === schoolId }]"
    ></el-option>
  </el-select>
</template>

<script>
import { select, option } from 'element-ui';
import debounce from 'throttle-debounce/debounce';
// api
import { getSchoolByKeyeordCloud } from '@/api/common';

const SCHOOL_DEFAULT_TEXT = '请输入学校名称';
const SCHOOL_EMPTY_TEXT = '无数据';

export default {
  name: 'schoolSelect',
  components: {
    ElSelect: select,
    ElOption: option,
  },
  props: {
    defaultSchool: Object,
  },
  data() {
    return {
      schoolId: '',
      schoolName: '',
      schoolKey: '',
      schoolList: [],
      debouncedOnSearch: null,
      emptyText: SCHOOL_DEFAULT_TEXT,
    };
  },
  watch: {
    defaultSchool: {
      immediate: true,
      async handler(val) {
        if (val && val.schoolId) {
          // 默认自动选中
          this.schoolKey = val.schoolName;
          this.schoolName = val.wholeName || val.schoolName;
          this.schoolId = this.schoolList.find((s) => s.schoolId === val.schoolId) ? val.schoolId : this.schoolName;
          this.$emit('select', val);
        }
      },
    },
  },
  methods: {
    // 获取学校列表
    getSchoolList() {
      return new Promise((resolve) => {
        if (!this.schoolKey.trim().length) {
          this.schoolList = [];
          return;
        }
        getSchoolByKeyeordCloud({
          schoolNameFuzzy: this.schoolKey,
        }).then((res) => {
          this.schoolList = (res.result || []).map((item) => ({
            ...item,
            wholeName: item.schoolName + (item.areaName || ''),
          }));
          this.emptyText = this.schoolList.length ? SCHOOL_DEFAULT_TEXT : SCHOOL_EMPTY_TEXT;
          resolve();
        });
      });
    },

    filterMethod(query) {
      this.schoolKey = query;
      this.debouncedOnSearch();
    },

    // 选择学校
    selectSchool(id) {
      let school = this.schoolList.find((item) => item.schoolId == id);
      this.schoolName = school.wholeName;
      this.$emit('select', school);
    },
  },
  created() {
    this.debouncedOnSearch = debounce(800, () => {
      this.getSchoolList();
    });
  },
};
</script>

<style lang="scss">
.school-select-popper {
  width: 340px;
  .el-select-dropdown__empty {
    padding: 96px 0;
  }
}
</style>
